import type React from "react";
import { useEffect } from "react";

import { useIsMounted } from "./use-is-mounted";

export const useMountedEffect = (
	effect: React.EffectCallback,
	deps?: React.DependencyList | undefined,
) => {
	const isMounted = useIsMounted();

	useEffect(() => {
		if (isMounted) {
			effect();
		}
	}, [isMounted]);
};
